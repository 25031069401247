<template>
  <v-card color="white" class="pa-8">
    <v-card-text class="px-0">
      <div>
        <div class="bsat__title">{{ $t("title") }}</div>

        <!-- ********************* OVERVIEW *************************** -->
        <section id="overview">
          <!-- <div class="bsat__paragraph">{{ $t("overview.paragraph") }}</div> -->
          <div class="bsat__paragraph">{{ $t("overview.paragraph2") }}</div>
        </section>

        <!-- ************************ IMPLEMENTATION RECOMMENDATIONS ********************************* -->
        <section id="implementationRecommendations">
          <div class="bsat__subtitle mb-4">
            <strong>{{ $t("implementationRecommendations.title") }}</strong>
          </div>

          <ReadMore :intro="$t('implementationRecommendationReadMore')">
            <i18n
              path="implementationRecommendations.task.text"
              tag="div"
              class="bsat__paragraph"
            >
              <template #title>
                <strong>{{
                  $t("implementationRecommendations.task.title")
                }}</strong>
              </template>
            </i18n>

            <i18n
              path="implementationRecommendations.taskLeader.text"
              tag="div"
              class="bsat__paragraph"
            >
              <template #title>
                <strong>{{
                  $t("implementationRecommendations.taskLeader.title")
                }}</strong>
              </template>
            </i18n>

            <div class="bsat__paragraph mb-4">
              <strong>{{
                $t("implementationRecommendations.proposedMethods.title")
              }}</strong>
            </div>

            <div>
              <ul>
                <li
                  class="bsat__paragraph mb-4"
                  v-for="(item, i) in proposedMethods"
                  :key="i"
                >
                  {{ item }}
                </li>
              </ul>
            </div>
          </ReadMore>
        </section>

        <!-- ***************************** RECOMMENDED RESOURCES ***************************** -->
        <section id="recommendedResources">
          <div class="bsat__subtitle mb-4">
            {{ $t("recommendedResources.title") }}
          </div>

          <!-- <div class="bsat__paragraph mt-n3 mb-6">
            {{ $t("recommendedResourcesIntro") }}
          </div> -->

          <ul class="ml-0 ml-sm-4 bsat__ul">
            <li>
              <i18n
                path="recommendedResources.paragraph.text"
                tag="div"
                class="bsat__paragraph"
              >
                <template #link1>
                  <a @click="goToStep(2)">{{
                    $t("recommendedResources.paragraph.link1")
                  }}</a>
                </template>
              </i18n>
            </li>

            <li>
              <div>
                <i18n
                  path="recommendedResources.paragraph2.text"
                  tag="div"
                  class="bsat__paragraph"
                >
                  <template #title>
                    <strong>{{
                      $t("recommendedResources.paragraph2.title")
                    }}</strong>
                  </template>
                </i18n>
                <!-- <div class="bsat__paragraph">
                  Document to be available within the end of February 2021
                </div> -->
                <a :href="canvasModelFile" target="_blank" download>
                  <v-btn
                    depressed
                    width="150"
                    height="26"
                    class="bsat__btn"
                    dark
                    color="dark"
                    >{{ $t("download") }}</v-btn
                  >
                </a>
              </div>
            </li>

            <li>
              <!-- ***************************** TOOLS REPOSITORY ***************************** -->
              <div>
                <i18n
                  path="toolsRepository.text"
                  tag="div"
                  class="bsat__paragraph"
                >
                  <template #title>
                    <strong>{{ $t("toolsRepository.title") }}</strong>
                  </template>
                </i18n>
                <v-btn
                  outlined
                  width="150"
                  height="26"
                  class="bsat__btn"
                  color="dark"
                  to="/repository-of-financing-instruments-and-tools"
                  >{{ $t("visit") }}</v-btn
                >
                <!-- <a
                  :href="require('@/assets/files/financial_tools.docx').default"
                  target="_blank"
                  download
                >
                  <v-btn
                    class="bsat__btn"
                    color="dark"
                    dark
                    depressed
                    width="150"
                    height="26"
                    >{{ $t("download") }}</v-btn
                  >
                </a> -->
              </div>
            </li>

            <li>
              <!-- ***************************** REGIONAL POLICIES ***************************** -->
              <div>
                <i18n
                  path="regionalPolicies.text"
                  tag="div"
                  class="bsat__paragraph"
                >
                  <template #title>
                    <strong>{{ $t("regionalPolicies.title") }}</strong>
                  </template>
                </i18n>
                <v-btn
                  outlined
                  width="150"
                  height="26"
                  class="bsat__btn"
                  color="dark"
                  to="/repository-of-regional-policies-to-support-bio-based-models"
                  >{{ $t("visit") }}</v-btn
                >
                <!-- <a
                  :href="require('@/assets/files/financial_tools.docx').default"
                  target="_blank"
                  download
                >
                  <v-btn
                    class="bsat__btn"
                    color="dark"
                    dark
                    depressed
                    width="150"
                    height="26"
                    >{{ $t("download") }}</v-btn
                  >
                </a> -->
              </div>
            </li>
          </ul>

          <!-- <div>
            <i18n
              path="toolsRepository.materials.text"
              tag="div"
              class="bsat__paragraph"
            >
              <template #title>
                <strong>{{ $t("toolsRepository.materials.title") }}</strong>
              </template>
              <template #link>
                <router-link :to="toolsRepositoryLink">
                  {{ $t("toolsRepository.materials.link") }}
                </router-link>
              </template>
            </i18n>
          </div> -->

          <!-- <div>
            <i18n
              path="regionalPolicies.materials.text"
              tag="div"
              class="bsat__paragraph"
            >
              <template #title>
                <strong>{{ $t("regionalPolicies.materials.title") }}</strong>
              </template>
              <template #link>
                <router-link :to="regionalPoliciesLink">
                  {{ $t("regionalPolicies.materials.link") }}
                </router-link>
              </template>
            </i18n>
          </div> -->
        </section>

        <i18n
          class="mt-8"
          tag="div"
          path="downloadAll.text"
          style="font-family: Montserrat"
        >
          <template #link>
            <a class="dark-text mt-8" :href="zip" target="_blank" download>
              {{ $t("downloadAll.link") }}
            </a>
          </template>
        </i18n>
      </div>
    </v-card-text>
  </v-card>
</template>

<i18n>
{
  "en": {
    "implementationRecommendations": {
      "proposedMethods": {
        "title": "Proposed methods",
        "unorderedList": [
          "List of current operational programs, their associated financial instruments and policy tools being used in the region. ",
          "Identification of policy and financial instruments used in the regional Smart Specialization Strategy."
        ]
      },
      "task": {
        "text": "{title}: Identify supportive resources and enabling mechanisms that could make possible the attainment of priority areas and objectives. (Already existing in the region)",
        "title": "Task"
      },
      "taskLeader": {
        "text": "{title}: Strategy development working group",
        "title": "Task leader"
      },
      "title": "Implementation Recommendations"
    },
    "overview": {
      "paragraph": "To enable the implementation of the strategy and once the priority areas and corresponding main objective are determined, the necessary resources and mechanisms to be used should be clarified. Starting by identifying available policy, as well as financial resources and mechanisms in the region. Along with those existent at national level, which might be used or might have an influence at regional level.",
      "paragraph2": "As supporting elements to consider, existing tools in the region must be evaluated. This includes identifying policy coordination mechanisms that might be employed between governmental departments or regional ministries involved at this point in the strategy development. Also, an initial inventory of related financial tools and incentives is encouraged including those destined to different sectors (agriculture, forestry, bioenergy, biorefineries, biotechnology) or areas of development (innovation activities (R&D+i), SMEs impulse, clusters, sustainability, CO2 reduction plans, circularity plans). Likewise, it is proposed to set up an inventory of regional bioeconomy (related) policies, and those from the national or EU level that influence programmes and plans in the region, such as those related to sustainability, circularity, agriculture, forest, land use, regional development, innovation, between others."
    },
    "recommendedResources": {
      "paragraph": {
        "link1": "Step 2",
        "text": "Determined priority areas for guidance ({link1})"
      },
      "paragraph2": {
        "text": "{title} For the economic analysis of cases, an standardised and well developed and structured Canvas Model might be used for internal purposes. ",
        "title": "Canvas Model: "
      },
      "title": "Recommended resources: "
    },
    "regionalPolicies": {
      "materials": {
        "link": "available",
        "text": "{title} To be made {link} in a new window. See list Annex 10.6 Specially consult resources no. 0 to no.5",
        "title": "Material: "
      },
      "text": "{title} From the regulatory point of view, consult how to create your good policy example in your region. Specially consult resource no. 5",
      "title": "Repository of supporting documents and tools for regional policies in the field of bioeconomy: "
    },
    "title": "Step 3: Identify available enabling mechanisms and resources for the bioeconomy strategy",
    "toolsRepository": {
      "materials": {
        "link": "available",
        "text": "{title} To be made {link} in a new window. See list Annex 10.7 Specially consult resources no. 0 to no.4",
        "title": "Material: "
      },
      "text": "{title} the following documents will help the regions assess the financial schemes and get familiar with the financial tools. Moreover, the region would be able to start collecting the key information to move on in the development of their own policy and incentives initiatives. Specially consult resources no.1 to no.4 ",
      "title": "Repository of supporting documents and tools for the implementation of EU financial instruments in the area of bioeconomy:",
      "downloadAll": {
			"link": "click here",
			"text": "*To download all the materials of Phase 3 and Phase 4 as a zip file please {link}"
		}
    },
    "downloadAll": {
			"link": "click here",
			"text": "*To download all the materials of Phase 3 and Phase 4 as a zip file please {link}"
		}
  }
}
</i18n>

<script>
import ReadMore from "@/components/ReadMore";
import { EventBus } from "@/utils/EventBus";
export default {
  name: "Phase3Step3",
  components: {
    ReadMore
  },
  data() {
    return {
      toolsRepositoryLink: "/repository-of-financing-instruments-and-tools",
      regionalPoliciesLink:
        "/repository-of-regional-policies-to-support-bio-based-models",
      canvasModelFile: require("@/assets/files/1_3_3_1_POWER4BIO_Business_Modelling_Methodology.docx")
        .default,
      zip: require("@/assets/files/Phase3_Phase4_common_resources.zip").default
    };
  },
  computed: {
    proposedMethods() {
      return Object.values(
        this.$t("implementationRecommendations.proposedMethods.unorderedList")
      );
    },
    // summaryUnorderedList() {
    //   return Object.values(this.$t("summary.unorderedList"));
    // },
    resourcesUnorderedList() {
      return Object.values(this.$t("recomendedResources.unorderedList"));
    }
  },
  methods: {
    goToTab(tab) {
      EventBus.$emit("goToTab", tab);
    },
    goToStep(step) {
      EventBus.$emit("goToStep", step);
    }
  }
};
</script>

<style scoped lang="scss">
@import "@/styles/main";

.list {
  $font-family: $font-family;
  // font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: justify;

  &-link {
    color: $light_green !important;
  }
}
</style>
